.mtop{
    margin-top: 30px;
}

.mbottom{
    margin-bottom: 30px;
}


#slides {
  position: relative;
}
#slides .slides-container {
  display: none;
}
#slides .scrollable {
  *zoom: 1;
  position: relative;
  top: 0;
  left: 0;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  height: 100%;
}
#slides .scrollable:after {
  content: "";
  display: table;
  clear: both;
}


.slides-navigation {
  margin: 0 auto;
  position: absolute;
  z-index: 3;
  top: 46%;
  width: 100%;
}


.slides-overlay{
  margin: 0 auto;
  position: absolute;
  z-index: 3;
  top:0;
  width: 100%;
}



.slide-caption{
  z-index: 3;
  margin-top:350px;
  color:#fff;
}


.slide-caption h2{
font-weight:300 !important;
}


.slides-navigation a {
  position: absolute;
  color:#fff;
  font-size:160%;
  display:block;
}
.slides-navigation a.prev {
  left: 0;
  padding-left:1em;
}
.slides-navigation a.next {
  right: 0;
  padding-right:1em;
}

.slides-pagination {
  position: absolute;
  z-index: 4;
  bottom: 20px;
  text-align: center;
  width: 100%;
}
.slides-pagination a {
  border: 2px solid #fff;
  border-radius: 15px;
  width: 15px;
  height: 15px;
  display: -moz-inline-stack;
  display: inline-block;
  vertical-align: middle;
/*  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR4nGP6zwAAAgcBApocMXEAAAAASUVORK5CYII=");*/
  *vertical-align: auto;
  zoom: 1;
  *display: inline;
  margin: 2px;
  overflow: hidden;
  text-indent: -230%;
}


.slides-pagination a:hover, 
.slides-pagination a.current {
background:#fff;
}



.main h1,
.main h2,
.main h3,
.main h4,
.main h5,
.main h6{
text-transform:uppercase;
font-weight:300;
}


h1 a:hover,h2 a:hover,h3 a:hover,h4 a:hover,h5 a:hover,h6 a:hover{
text-decoration:none;
}


.jumbotron{
height:300px;
background:url('../img/bg1-album-blue.jpg');
background-position:center;
}


.home .jumbotron{
height:auto;
background:#fff;
}

 .nav {
 text-transform:uppercase;
 }
 
 
 .xtrapadding{
 padding:3em;
 }
 
 .sidebar-part{
/* padding-left:1em;*/
 padding-bottom:1em;
 }

body{
/*padding-top:50px;*/
}

.site-name h1,
.site-name div{
color:#fff;
font-size:40px !important;
/*text-shadow: 0px 0px 11px rgba(50, 50, 50, 1), 0px 0px 3px rgba(50, 50, 50, 1);*/
margin-top:130px;
text-transform:uppercase;
}

.home .site-name{
padding-top:130px;
}


.home-slide-link{
    color:#fff !important;
}

a.home-slide-link:hover{
    color:#ddd !important;
}



.post-summary{margin-top:1em;}

.post-actions, .album-actions{
margin-top:30px;
}

.post-actions a{
margin:0 1em 1em 0;
display:block;
float:left;
}

.album-actions a{
margin:0 1em 1em 0;
display:block;
float:left;
clear:right;
}

.post-actions a:hover,
.album-actions a:hover{
text-decoration:none !important;
}

.post-footer{
margin-top:1em;
border-bottom:1px solid #aaa;
margin-bottom:1em;
padding-bottom:1em;
}


.album{
margin-bottom:15px;
}

#mc_embed_signup{
padding-bottom:1em;
}

 #mc_embed_signup input{
   margin-bottom:15px !important;
	}


.inverse .social-icons li a i,
.page-footer .social-icons li a i{
border-radius:50%;
}

.inverse .social-icons li a:hover i{
color:#222;
background:#fff;
}


.footer{
margin-top:30px;
}

nav.album-action li:hover{
background:transparent;
}

.gal-item{
margin-bottom:15px;
min-height:210px;
}

.item-margin-top{
margin-top:15px;
}

.in-page-gallery .gal-item{
margin:7px 0 7px 0;
}

.article-gallery .gal-item{
min-height:184px;
}

.negative-margin-bottom-30{
margin-bottom:-30px;
}

.negative-margin-bottom-15{
margin-bottom:-15px;
}

/*lightbox starts*/
/* Preload images */
body:after {
  content: url(../img/close.png) url(../img/loading.gif) url(../img/prev.png) url(../img/next.png);
  display: none;
}

.lightboxOverlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: black;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  opacity: 0.8;
  display: none;
}

.lightbox {
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 10000;
  text-align: center;
  line-height: 0;
  font-weight: normal;
}

.lightbox .lb-image {
  display: block;
  height: auto;
  max-width: inherit;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
}

.lightbox a img {
  border: none;
}

.lb-outerContainer {
  position: relative;
  background-color: white;
  *zoom: 1;
  width: 250px;
  height: 250px;
  margin: 0 auto;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
}

.lb-outerContainer:after {
  content: "";
  display: table;
  clear: both;
}

.lb-container {
  padding: 4px;
}

.lb-loader {
  position: absolute;
  top: 43%;
  left: 0;
  height: 25%;
  width: 100%;
  text-align: center;
  line-height: 0;
}

.lb-cancel {
  display: block;
  width: 32px;
  height: 32px;
  margin: 0 auto;
  background: url(../img/loading.gif) no-repeat;
}

.lb-nav {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
}

.lb-container > .nav {
  left: 0;
}

.lb-nav a {
  outline: none;
  background-image: url('data:image/gif;base64,R0lGODlhAQABAPAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==');
}

.lb-prev, .lb-next {
  height: 100%;
  cursor: pointer;
  display: block;
}

.lb-nav a.lb-prev {
  width: 34%;
  left: 0;
  float: left;
  background: url(../img/prev.png) left 48% no-repeat;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  -o-transition: opacity 0.6s;
  transition: opacity 0.6s;
}

.lb-nav a.lb-prev:hover {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}

.lb-nav a.lb-next {
  width: 64%;
  right: 0;
  float: right;
  background: url(../img/next.png) right 48% no-repeat;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  -o-transition: opacity 0.6s;
  transition: opacity 0.6s;
}

.lb-nav a.lb-next:hover {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}

.lb-dataContainer {
  margin: 0 auto;
  padding-top: 5px;
  *zoom: 1;
  width: 100%;
  -moz-border-radius-bottomleft: 4px;
  -webkit-border-bottom-left-radius: 4px;
  border-bottom-left-radius: 4px;
  -moz-border-radius-bottomright: 4px;
  -webkit-border-bottom-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.lb-dataContainer:after {
  content: "";
  display: table;
  clear: both;
}

.lb-data {
  padding: 0 4px;
  color: #ccc;
}

.lb-data .lb-details {
  width: 85%;
  float: left;
  text-align: left;
  line-height: 1.1em;
}

.lb-data .lb-caption {
  font-size: 13px;
  font-weight: bold;
  line-height: 1em;
}

.lb-data .lb-number {
  display: block;
  clear: left;
  padding-bottom: 1em;
  font-size: 12px;
  color: #999999;
}

.lb-data .lb-close {
  display: block;
  float: right;
  width: 30px;
  height: 30px;
  background: url(../img/close.png) top right no-repeat;
  text-align: right;
  outline: none;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
  opacity: 0.7;
  -webkit-transition: opacity 0.2s;
  -moz-transition: opacity 0.2s;
  -o-transition: opacity 0.2s;
  transition: opacity 0.2s;
}

.lb-data .lb-close:hover {
  cursor: pointer;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}




/*lightbox ends*/

@media (min-width: 768px){



.gal-item{
margin-bottom:30px;
}

.item-margin-top{
margin-top:30px;
}

.in-page-gallery .gal-item{
margin:15px 0 15px 0;
}


    .main-menu  .navbar-nav {
        margin: 0 auto;
        display: table;
        table-layout: auto;
        float: none;
        position: relative;
        top: 2px;
    }
 
    .main-menu   .navbar-nav>li {
        display: table-cell;
        float: none;
        text-align: center;
    }
    
    .main-menu .navbar-nav>li {
        float: none;

}        



.main{
min-height:500px;
}


.slides-overlay .navbar-collapse{
padding-left:0 !important;
padding-right:0 !important;
}


.site-name h1,
.site-name div{
font-size:64px!important;
margin-top:120px;
}


} 


/*eof media query*/
